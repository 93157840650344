.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.day-view .day-square {
  margin: auto;
  height: 200px;
  width: 200px;
  font-size: 3em;
  color: black;
}

.score-button {
  min-width: 80px;
  min-height: 30px;
}

a {
  text-decoration: none;
  color: white;
}

p.error {
  color: red;
  font-size: 0.7em;
}

p.user-header {
  font-size: 0.7em;
}

p.user-header a {
  text-decoration: underline;
}

a.underscored-link {
  text-decoration: underline;
  font-size: 0.7em;
}


/* Auth forms */

form.auth-form label {
  font-size: 1.5em;
}

form.auth-form .label-container {
  text-align: left;
}

form.auth-form input[type="email"],
form.auth-form input[type="password"] {
  font-size: 1em;
  width: 60vw;
}

form.auth-form button {
  font-size: 1.3em;
  width: 100%;
  padding: 0.3em;
  margin-top: 1.4em;
}

form.auth-form .link-to-other-auth-text {
  font-size: 1.5em;
}

form.auth-form .link-to-other-auth {
  font-size: 1em;
}

form.auth-form p.error {
  font-size: 1.3em;
}


/* Auth form - big screen */
@media only screen and (min-device-width: 667px) {
  .auth-container h1 {
    font-size: 1.7em;
  }

  form.auth-form {
    width: 300px;
  }

  form.auth-form input[type="email"],
  form.auth-form input[type="password"] {
    font-size: 0.5em;
    width: 290px;
  }

  form.auth-form label {
    font-size: 0.7em;
  }

  form.auth-form button {
    font-size: 0.6em;
    width: 300px;
    padding: 0.3em;
    margin-top: 1.4em;
  }

  form.auth-form .link-to-other-auth-text {
    font-size: 0.6em;
  }

  form.auth-form #link-to-other-auth {
    font-size: 1em;
  }

  form.auth-form p.error {
    width: 290px;
    font-size: 0.6em;
  }
  
}


/* Columnar calendar */

.columnar-calendar tbody td {
  font-size: 3vh;
}

.columnar-calendar a {
  text-decoration: none;
  color: white;
}

.columnar-calendar .today-button {
  margin-bottom: 0.7em;
  width: 100%;
  font-size: 1.5em;
}

/* Columnar calendar - big screen */
@media only screen and (min-device-width: 667px) {
  .columnar-calendar tbody td {
    font-size: 2vh;
  }

  .columnar-calendar .today-button {
    margin-bottom: 0.5em;
    width: 100%;
    font-size: 0.8em;
  }
}
